


























import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MySupplyCard extends Vue {
  @Prop() wideHigh!: number;
  @Prop() items!:any
  @Prop() select!:any
  @Prop() index!:any
  @Emit()
  checksupply(index:any){
      return index
  }
  get check() {
    if(this.select.indexOf(this.index) == -1){
        return false
    }else{
        return true
    }
  }
}
