









import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class BottomButton extends Vue {
  created() {}
  @Prop() need_num!:any
  @Emit()
  toChange(){
      return
  }
}
